<template>
    <div v-if="respData && $ability.can('read', this.$route.meta.resource)">

        <b-card :title="$t('Support')">
            <b-row>
                <b-col cols="12">
                    <b-table-simple>
                        <b-thead head-variant="primary">
                            <b-tr>
                                <b-td style="width: 20%;">{{ $t('Support list') }}</b-td>
                                <b-td>{{ $t('Setting') }}</b-td>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ $t('Supported Countries') }} : </b-td>
                                <b-td>
                                    <v-select multiple label="name" v-model="supportedCountries"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        @input="multiSelectChange($event, 'supportedCountries')"
                                        :options="respCountryData" :reduce="(respCountryData) => respCountryData._id" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('Default Language') }} : </b-td>
                                <b-td>
                                    <v-select label="name" v-model="defaultLanguage" :clearable="false"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        @input="multiSelectChange($event, 'defaultLanguage')" :options="language"
                                        :reduce="(language) => language.lang" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('Multi Currency') }} : </b-td>
                                <b-td>
                                    <b-row>
                                        <b-form-checkbox :checked="respData['multiCurrency'] != null ? respData['multiCurrency'] : false
                                            " class="custom-control-primary ml-2" name="check-button" switch
                                            @change="switchChange('multiCurrency', $event)" />
                                        <b-card-text>
                                            {{
                                                respData["multiCurrency"] != null
                                                    ? respData["multiCurrency"] == true
                                                        ? "เปิดระบบหลายสกุลเงิน"
                                                        : "ปิดระบบหลายสกุลเงิน"
                                                    : "ยังไม่ได้ตั้งค่า"
                                            }}
                                        </b-card-text>
                                    </b-row>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="respData.multiCurrency !== null && respData.multiCurrency === true">
                                <b-td>{{ $t('Currency') }} : </b-td>
                                <b-td>
                                    <v-select label="name" v-model="defaultCurrency"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        @input="multiSelectChange($event, 'defaultCurrency')"
                                        :options="respCurrencyData"
                                        :reduce="(respCurrencyData) => respCurrencyData._id" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ $t('Use Address From System') }} : </b-td>
                                <b-td>
                                    <b-row>
                                        <b-form-checkbox :checked="respData['useAddressFromSystem'] != null ? respData['useAddressFromSystem'] : false
                                            " class="custom-control-primary ml-2" name="check-button" switch
                                            @change="switchChange('useAddressFromSystem', $event)" />
                                        <b-card-text>
                                            {{
                                                respData["useAddressFromSystem"] != null
                                                    ? respData["useAddressFromSystem"] == true
                                                        ? "เปิดการใช้ที่อยู่จากระบบ"
                                                        : "ปิดการใช้ที่อยู่จากระบบ"
                                                    : "ยังไม่ได้ตั้งค่า"
                                            }}
                                        </b-card-text>
                                    </b-row>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>


            </b-row>
        </b-card>

    </div>
</template>

<script>
import { BTableSimple, BThead, BTh, BTbody, BTr, BTd } from 'bootstrap-vue'
import {
    // BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    // BFormGroup,
    // BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/eCommerce/setSupport'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'setSupport'

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        vSelect,
        // BButton,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormCheckbox,
        BTableSimple, BThead, BTh, BTbody, BTr, BTd
        // BFormGroup,
        // BFormInput,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            supportedCountries: [],
            defaultLanguage: '',
            defaultCurrency: '',
        }
    },
    computed: {
        respData() {
            return store.state[STORE_MODULE_NAME].respData
        },
        respCountryData() {
            return store.state[STORE_MODULE_NAME].respCountryData ? store.state[STORE_MODULE_NAME].respCountryData : []
        },
        respCurrencyData() {
            return store.state[STORE_MODULE_NAME].respCurrencyData ? store.state[STORE_MODULE_NAME].respCurrencyData : []
        },
        language() {
            return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
        },
    },
    created() {
        // Register module
        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, StoreModule)
        }
        store
            .dispatch(`${STORE_MODULE_NAME}/get`)
            .then(result => {

                let data = result.data.data
                if (data.supportedCountries != null) {
                    this.supportedCountries = data.supportedCountries
                    this.getCurrency(data.supportedCountries)
                }
                if (data.defaultLanguage != null) {
                    this.defaultLanguage = data.defaultLanguage
                }
                if (data.defaultCurrency != null) {
                    this.defaultCurrency = data.defaultCurrency
                }
            })
            .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                        title: this.$t('Error'),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                        text: this.$t(error.response.data.message),
                    },
                })
            })
        store
            .dispatch(`${STORE_MODULE_NAME}/getCountry`)
            .then(result => {

            })
            .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                        title: this.$t('Error'),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                        text: this.$t(error.response.data.message),
                    },
                })
            })

    },
    destroyed() {
        if (store.hasModule(STORE_MODULE_NAME)) {
            store.unregisterModule(STORE_MODULE_NAME)
        }
    },
    methods: {
        getCurrency(data) {
            store
                .dispatch(`${STORE_MODULE_NAME}/getCurrency`, { data })
                .then(result => {

                })
                .catch(error => {
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                })
        },
        multiSelectChange(data, type) {
            store
                .dispatch(`${STORE_MODULE_NAME}/multiSelectChange`, { data, type })
                .then(result => {
                    if (type === 'supportedCountries') {
                        this.getCurrency(result.data.data.supportedCountries)
                    }

                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Success'),
                            icon: 'SuccessIcon',
                            variant: 'success',
                            text: this.$t(result.data.message),
                        },
                    })
                })
                .catch(error => {
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                })
        },
        inputChange(name) {
            const obj = {
                currentVersion: store.state[STORE_MODULE_NAME].respData.currentVersion,
            }
            if (name === 'currentVersion') {
                obj.currentVersion = this.currentVersion
            }
            console.log(name)

            store
                .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
                .then(result => {

                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Success'),
                            icon: 'SuccessIcon',
                            variant: 'success',
                            text: this.$t(result.data.message),
                        },
                    })
                })
                .catch(error => {
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                })
        },
        switchChange(name, status) {

            const obj = {
                name,
                status,
            }
            store
                .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
                .then(result => {

                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Success'),
                            icon: 'SuccessIcon',
                            variant: 'success',
                            text: this.$t(result.data.message),
                        },
                    })
                })
                .catch(error => {
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>