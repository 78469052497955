import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respCountryData: null,
    respCurrencyData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_COUNTRY(state, payload) {
      state.respCountryData = payload.map((e)=>{
        return {
          _id: e._id,
          name: `${e.emoji} ${e.name} (${e.native}) (${e.phone_code.replaceAll("+", "")})`,
        }
      })
    },
    GET_CURRENCY(state, payload) {
      state.respCurrencyData = payload.map((e)=>{
        return {
          _id: e._id,
          name: `(${e.currency_symbol}) ${e.currency}`,
        }
      })
    },
    SWITCH_CHANGE(state, item) {
      const nameChild = item.name.split('.')
      
      if (nameChild.length === 3) {
        state.respData[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
      } else if (nameChild.length === 2) {
        state.respData[nameChild[0]][nameChild[1]] = item.status
      } else {
        state.respData[item.name] = item.status
      }
    },
    INPUT_CHANGE(state, item) {
      const nameChild = item.name.split('.')
      
      if (nameChild.length === 2) {
        state.respData[nameChild[0]][nameChild[1]] = item.value
      } else if (nameChild.length === 3) {
        state.respData[nameChild[0]][nameChild[1]][nameChild[2]] = item.value
      } else {
        state.respData[item.name] = item.value
      }
    },
    UPDATE(state, payload) {
      state.respData = payload
      
    },
  },
  actions: {
    get({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/setSupport/get')
          .then(response => {
            
            commit('GET', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCountry({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/setSupport/getCountry')
          .then(response => {
            
            commit('GET_COUNTRY', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCurrency({ commit },item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/web/application/eCommerce/setSupport/getCurrency', item)
          .then(response => {
            
            commit('GET_CURRENCY', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    inputChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/setSupport/inputChange', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    multiSelectChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/setSupport/multiSelectChange', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    switchChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/setSupport/switchChange', item)
          .then(response => {
            commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
